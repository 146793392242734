import {Typography} from '@hconnect/uikit'
import {CircularProgress} from '@mui/material'
import React from 'react'

import {useCustomer} from '../../../hooks/useCustomer'

type CompanyNameProps = {
  isCloneable: boolean
  customerId: string
}
export const CompanyName = ({isCloneable, customerId}: CompanyNameProps) => {
  const {data, isLoading} = useCustomer({customerId}, false)

  if (isLoading) {
    return <CircularProgress size={20} />
  }

  return (
    <Typography style={{opacity: isCloneable ? 1 : 0.5, textAlign: 'left'}}>
      {data?.customerName}
    </Typography>
  )
}

import {cloneDeep, uniq, isEqual, sortBy} from 'lodash'

export interface RoleAssignment {
  autoGenerated?: boolean
  id: number
  roleType: RoleType
  userId: string
  dataScope: DataScopes
  isInternal?: boolean
  expiryDate?: string
}

export interface CustomersScopes {
  customerId: string
  siteIds: string[]
  projectIds: string[]
  contractIds: string[]
  roleId: number
}

// To get them, download rolerules.json and run:
// rules.Roles.map(({roleType}) => `'${roleType}'`).join(' | ')

export type RoleType =
  | 'FINISHER'
  | 'SUPERINTEDENT'
  | 'GLOBAL_ADMIN'
  | 'SUPER_ADMIN'
  | 'SALES_AGENT'
  | 'DISPATCHER'
  | 'PLANT_MANAGER'
  | 'BUSINESS_OWNER'
  | 'CUSTOMER_ADMIN'
  | 'ACCOUNTANT'
  | 'QA_ENGINEER'
  | 'ANALYTICS'
  | 'DELIVERIES_APPROVER'
  | 'MANAGING_DIRECTOR'
  | 'PLANT_DIRECTOR'
  | 'PRODUCTION_SUPERVISOR'
  | 'ENERGY_MANAGER'
  | 'CONTROL_ROOM_OPERATOR'
  | 'SERVICE_ACCOUNT'
  | 'SUPPORT_AGENT'
  | 'QC_SUPERVISOR'
  | 'BACKEND_SERVICE'
  | 'CONTROLLER'
  | 'ORDER_PLACER'
  | 'SHIFT_LEADER'
  | 'DATALINK_DEVELOPER'
  | 'DATALINK_INTEGRATION_PARTNER'
  | 'DATALINK_PENDING_DEVELOPER'
  | 'EXPORT_ADMIN'
  | 'FEATURES_ADMIN'
  | 'ORDER_PUSHER'
  | 'CARBON_BANK_ADMIN'
  | 'CARBON_BANK_ALLOCATION_ASSURER'
  | 'CARBON_BANK_CUSTOMER'
  | 'CARBON_BANK_SAVING_ASSURER'
  | 'QC_VIEWER'
  | 'HAULIER'
  | 'CUSTOMER_SERVICE_CENTER'
  | 'CARBON_BANK_HM_ALLOCATION_ADMIN'
  // TODO: fix reducer and do this properly.
  | ''

export interface GroupedRoleAssignment {
  autoGenerated?: boolean
  groupId: string | null
  sourceRolesIds: number[]
  // TODO change to RoleType
  roleType: RoleType
  userId: string
  commonDataScopes: DataScopes
  variantDataScopes: {
    allCustomersRoleId: number | null
    customersScopes: CustomersScopes[]
    payerIds?: string[]
    plantIds?: string[]
    commentCategories?: string[]
    haulierIds?: string[]
  }
  unsupportedDataScopes: DataScopes
  expiryDate?: string
}

export interface DataScopes {
  [dataScopeName: string]: string | string[]
}

// eslint-disable-next-line complexity
const getVariantDataScopes = (
  currentRoleAssignment: RoleAssignment
): Partial<GroupedRoleAssignment['variantDataScopes']> & {customersScopes: CustomersScopes[]} => {
  const {
    customerIds,
    siteIds,
    projectIds,
    payerIds,
    plantIds,
    contractIds,
    commentCategories,
    haulierIds
  } = currentRoleAssignment.dataScope

  if (!customerIds || !Array.isArray(customerIds) || customerIds.length === 0) {
    if (payerIds && Array.isArray(payerIds) && payerIds.length > 0) {
      return {
        customersScopes: [],
        payerIds
      }
    }
    if (plantIds && Array.isArray(plantIds) && plantIds.length > 0) {
      const commentCategoriesExist =
        commentCategories && Array.isArray(commentCategories) && commentCategories.length > 0
      return {
        customersScopes: [],
        plantIds,
        ...(commentCategoriesExist ? {commentCategories} : {})
      }
    }
    if (haulierIds && Array.isArray(haulierIds) && haulierIds.length > 0) {
      return {
        customersScopes: [],
        haulierIds
      }
    }
    return {
      allCustomersRoleId: currentRoleAssignment.id,
      customersScopes: []
    }
  }

  if (customerIds.length === 1) {
    return {
      customersScopes: [
        {
          customerId: customerIds[0],
          siteIds: Array.isArray(siteIds) ? siteIds : [],
          projectIds: Array.isArray(projectIds) ? projectIds : [],
          contractIds: Array.isArray(contractIds) ? contractIds : [],
          roleId: currentRoleAssignment.id
        }
      ]
    }
  }

  return {
    customersScopes: customerIds.map((customerId) => ({
      customerId,
      siteIds: [],
      projectIds: [],
      contractIds: [],
      roleId: currentRoleAssignment.id
    }))
  }
}

export const unsupportedGroupingDataScopes = new Set([
  'plantId',
  'dispatchGroupId',
  'orderIds',
  'areaId'
])

export const areDataScopesUngroupable = (dataScopes: DataScopes): boolean => {
  for (const key in dataScopes) {
    if (unsupportedGroupingDataScopes.has(key)) {
      return true
    }
  }
  return false
}

export const editableDataScopes = new Set([
  'areaId',
  'countryId',
  'businessLine',
  'orgUnitId',
  'customerIds',
  'siteIds',
  'projectIds',
  'plantId',
  'dispatchGroupId',
  'payerIds',
  'plantIds',
  'contractIds',
  'commentCategories',
  'apiProducts',
  'haulierIds'
])

export const areDataScopesSupportedByUI = (dataScopes: DataScopes): boolean => {
  for (const key in dataScopes) {
    if (!editableDataScopes.has(key)) {
      return false
    }
  }
  return true
}

const filterDataScopes = (dataScopes: DataScopes, supported: boolean): DataScopes =>
  Object.keys(dataScopes)
    .filter(
      (x) => (supported && editableDataScopes.has(x)) || (!supported && !editableDataScopes.has(x))
    )
    .reduce(
      (acc, dataScopeName) => ({
        ...acc,
        [dataScopeName]: dataScopes[dataScopeName]
      }),
      {}
    )

const mergeArrays = (
  newArray: string[] | undefined,
  existingArray: string[] | undefined
): string[] | undefined => {
  if (!newArray) {
    return existingArray
  }

  const mergedArray = newArray
  if (existingArray) {
    mergedArray.push(...existingArray)
  }
  return uniq(mergedArray)
}

export const isRoleTypeUngroupable = (roleType: string) => {
  return roleType === 'ORDER_PLACER'
}

// eslint-disable-next-line complexity
const roleBelongsToGroup = (role: RoleAssignment, group: GroupedRoleAssignment): boolean => {
  if (
    areDataScopesUngroupable(group.commonDataScopes) ||
    Object.keys(group.unsupportedDataScopes).length !== 0 ||
    group.roleType !== role.roleType
  ) {
    return false
  }

  const currentDataScopes = role.dataScope
  const commonDataScopes = group.commonDataScopes
  const variableDataScopes = group.variantDataScopes

  // Check the commonDataScope
  if (
    commonDataScopes.countryId !== currentDataScopes.countryId ||
    commonDataScopes.orgUnitId !== currentDataScopes.orgUnitId ||
    commonDataScopes.businessLine !== currentDataScopes.businessLine
  ) {
    return false
  }

  // Check the payerIds
  if (
    !(
      variableDataScopes.payerIds &&
      variableDataScopes.payerIds.length > 0 &&
      currentDataScopes.payerIds &&
      currentDataScopes.payerIds.length > 0
    ) &&
    (variableDataScopes.payerIds || currentDataScopes.payerIds)
  ) {
    return false
  }

  // Check the plantIds
  if (
    !(
      variableDataScopes.plantIds &&
      variableDataScopes.plantIds.length > 0 &&
      currentDataScopes.plantIds &&
      currentDataScopes.plantIds.length > 0
    ) &&
    (variableDataScopes.plantIds || currentDataScopes.plantIds)
  ) {
    return false
  }

  // Check the commentCategories
  if (
    !(
      variableDataScopes.commentCategories &&
      variableDataScopes.commentCategories.length > 0 &&
      currentDataScopes.commentCategories &&
      currentDataScopes.commentCategories.length > 0
    ) &&
    (variableDataScopes.commentCategories || currentDataScopes.commentCategories)
  ) {
    return false
  }

  // Check the haulierIds
  if (
    !(
      variableDataScopes.haulierIds &&
      variableDataScopes.haulierIds.length > 0 &&
      currentDataScopes.haulierIds &&
      currentDataScopes.haulierIds.length > 0
    ) &&
    (variableDataScopes.haulierIds || currentDataScopes.haulierIds)
  ) {
    return false
  }

  return true
}

export const groupRoleAssignments = (roleAssignments: RoleAssignment[]): GroupedRoleAssignment[] =>
  roleAssignments.reduce<Array<GroupedRoleAssignment>>(
    // eslint-disable-next-line complexity
    (groupedAssignments, currentRoleAssignment) => {
      const unsupportedDataScopes = filterDataScopes(currentRoleAssignment.dataScope, false)

      const foundAssignmentIndex =
        isRoleTypeUngroupable(currentRoleAssignment.roleType) ||
        areDataScopesUngroupable(currentRoleAssignment.dataScope) ||
        Object.keys(unsupportedDataScopes).length > 0
          ? -1
          : groupedAssignments.findIndex((group) =>
              roleBelongsToGroup(currentRoleAssignment, group)
            )
      const {
        allCustomersRoleId,
        customersScopes,
        payerIds,
        plantIds,
        commentCategories,
        haulierIds
      } = getVariantDataScopes(currentRoleAssignment)

      if (foundAssignmentIndex === -1) {
        const {payerIds, plantIds, commentCategories, ...remainingDataScopes} =
          currentRoleAssignment.dataScope

        const possibleCommonDataScopes = {...remainingDataScopes}

        delete possibleCommonDataScopes.customerIds
        delete possibleCommonDataScopes.siteIds
        delete possibleCommonDataScopes.projectIds
        delete possibleCommonDataScopes.contractIds

        const groupedRoleAssignment: GroupedRoleAssignment = {
          autoGenerated: currentRoleAssignment.autoGenerated,
          groupId: currentRoleAssignment.id.toString(),
          sourceRolesIds: [currentRoleAssignment.id],
          roleType: currentRoleAssignment.roleType,
          userId: currentRoleAssignment.userId,
          expiryDate: currentRoleAssignment.expiryDate,
          commonDataScopes: filterDataScopes(possibleCommonDataScopes, true),
          variantDataScopes: {
            allCustomersRoleId: allCustomersRoleId || null,
            customersScopes
          },
          unsupportedDataScopes
        }

        if (payerIds) {
          if (Array.isArray(payerIds)) {
            groupedRoleAssignment.variantDataScopes.payerIds = payerIds
          } else {
            groupedRoleAssignment.variantDataScopes.payerIds = [payerIds]
          }
        }

        if (commentCategories) {
          if (Array.isArray(commentCategories)) {
            groupedRoleAssignment.variantDataScopes.commentCategories = commentCategories
          } else {
            groupedRoleAssignment.variantDataScopes.commentCategories = [commentCategories]
          }
        }

        if (plantIds) {
          if (Array.isArray(plantIds)) {
            groupedRoleAssignment.variantDataScopes.plantIds = plantIds
          } else {
            groupedRoleAssignment.variantDataScopes.plantIds = [plantIds]
          }
        }

        if (haulierIds) {
          if (Array.isArray(haulierIds)) {
            groupedRoleAssignment.variantDataScopes.haulierIds = haulierIds
          } else {
            groupedRoleAssignment.variantDataScopes.haulierIds = [haulierIds]
          }
        }

        groupedAssignments.push(groupedRoleAssignment)
        return groupedAssignments
      }

      const foundRoleAssignment = groupedAssignments[foundAssignmentIndex]

      if (!foundRoleAssignment.sourceRolesIds.includes(currentRoleAssignment.id)) {
        foundRoleAssignment.sourceRolesIds.push(currentRoleAssignment.id)
      }
      foundRoleAssignment.sourceRolesIds = foundRoleAssignment.sourceRolesIds.sort()
      foundRoleAssignment.groupId = foundRoleAssignment.sourceRolesIds.sort().join('#')

      foundRoleAssignment.variantDataScopes.customersScopes = [
        ...foundRoleAssignment.variantDataScopes.customersScopes,
        ...customersScopes
      ]

      if (allCustomersRoleId) {
        foundRoleAssignment.variantDataScopes.allCustomersRoleId = allCustomersRoleId
      }

      foundRoleAssignment.variantDataScopes.payerIds = mergeArrays(
        payerIds,
        foundRoleAssignment.variantDataScopes.payerIds
      )

      foundRoleAssignment.variantDataScopes.plantIds = mergeArrays(
        plantIds,
        foundRoleAssignment.variantDataScopes.plantIds
      )

      foundRoleAssignment.variantDataScopes.commentCategories = mergeArrays(
        commentCategories,
        foundRoleAssignment.variantDataScopes.commentCategories
      )

      foundRoleAssignment.variantDataScopes.haulierIds = mergeArrays(
        haulierIds,
        foundRoleAssignment.variantDataScopes.haulierIds
      )

      return groupedAssignments
    },
    []
  )

const areArraysEqual = (a1: unknown, a2: unknown): boolean => {
  if (a1 === undefined && a2 === undefined) {
    return true
  }
  if (a1 === undefined || a2 === undefined) {
    return false
  }
  if (!Array.isArray(a1) || !Array.isArray(a2)) {
    return false
  }
  return isEqual(sortBy(a1), sortBy(a2))
}

export const areDataScopesEqual = (
  d1: {
    [dataScopeName: string]: string | string[]
  },
  d2: {
    [dataScopeName: string]: string | string[]
  }
): boolean => {
  if (!areArraysEqual(Object.keys(d1), Object.keys(d2))) {
    return false
  }

  for (const key in d1) {
    if (Array.isArray(d1[key]) || Array.isArray(d2[key])) {
      if (!areArraysEqual(d1[key], d2[key])) {
        return false
      }
    } else {
      if (d1[key] !== d2[key]) {
        return false
      }
    }
  }
  return true
}

export const areRoleAssignmentsEqual = (r1: RoleAssignment, r2: RoleAssignment): boolean =>
  r1.id === r2.id &&
  r1.roleType === r2.roleType &&
  r1.userId === r2.userId &&
  areDataScopesEqual(r1.dataScope, r2.dataScope)

export interface RoleUpdateOperation {
  type: 'POST' | 'PUT' | 'DELETE'
  payload: RoleAssignment
}

export const getUpdateOperationsFromAssignments = (
  sourceAssignments: RoleAssignment[],
  targetAssignments: RoleAssignment[]
): Array<RoleUpdateOperation> => {
  let updateOperations: Array<RoleUpdateOperation> = []
  // POST operations
  updateOperations = updateOperations.concat(
    targetAssignments
      .filter((x) => x.id === -1)
      .map((x) => ({
        type: 'POST',
        payload: x
      }))
  )

  // PUT operations
  const putOperations: Array<RoleUpdateOperation> = []

  for (const targetAssignment of targetAssignments.filter((x) => x.id !== -1)) {
    const sourceAssignment = sourceAssignments.find((x) => x.id === targetAssignment.id)
    if (!sourceAssignment) {
      // TODO: Throw exception because there is a role without source
      continue
    }

    if (!areRoleAssignmentsEqual(targetAssignment, sourceAssignment)) {
      putOperations.push({
        type: 'PUT',
        payload: targetAssignment
      })
    }
  }

  updateOperations = updateOperations.concat(putOperations)

  // DELETE operations
  return updateOperations.concat(
    sourceAssignments
      .filter((x) => targetAssignments.findIndex((y) => y.id === x.id) < 0)
      .map((x) => ({
        type: 'DELETE',
        payload: x
      }))
  )
}

const reassignUnusedRoleIds = (
  roleAssignments: RoleAssignment[],
  groupedRoleAssignment: GroupedRoleAssignment
): RoleAssignment[] => {
  const currentAssignedRoleIds = new Set(roleAssignments.map((x) => x.id).filter((x) => x !== -1))

  const remainingUnassignedIds = groupedRoleAssignment.sourceRolesIds.filter(
    (x) => !currentAssignedRoleIds.has(x)
  )

  roleAssignments.every((roleAssignment, index) => {
    if (remainingUnassignedIds.length === 0) {
      return false
    }

    if (roleAssignment.id === -1) {
      roleAssignments[index].id = remainingUnassignedIds.shift() || -1
    }

    return true
  })

  return roleAssignments
}

const getBaseRoleAssignment = (groupedRoleAssignment: GroupedRoleAssignment): RoleAssignment => {
  const baseRoleAssignment: RoleAssignment = {
    id: -1,
    userId: groupedRoleAssignment.userId,
    roleType: groupedRoleAssignment.roleType,
    dataScope: {
      ...groupedRoleAssignment.commonDataScopes
    }
  }

  return cloneDeep(baseRoleAssignment)
}
// eslint-disable-next-line
export const expandGroupedRoleAssignments = (gra: GroupedRoleAssignment): Array<RoleAssignment> => {
  const roleAssignments: Array<RoleAssignment> = []
  if (Object.keys(gra.unsupportedDataScopes).length > 0) {
    throw new Error('Grouped role contains unsupported DataScopes')
  }

  if (gra.variantDataScopes.allCustomersRoleId === null) {
    if (
      gra.variantDataScopes.customersScopes.length > 0 &&
      gra.variantDataScopes.payerIds &&
      gra.variantDataScopes.payerIds.length > 0
    ) {
      throw new Error('Cannot have payerIds and Customers for the same grouped role')
    }

    if (gra.variantDataScopes.customersScopes.length > 0) {
      // Group all users scopes: One role for all customers
      const allProjectsSitesForCustomers = gra.variantDataScopes.customersScopes.filter(
        (x) => !x.siteIds.length && !x.projectIds.length && !x.contractIds.length
      )
      const allCustomersIds = uniq(allProjectsSitesForCustomers.map((x) => x.customerId).sort())

      if (allCustomersIds.length > 0) {
        const allProjectsSitesForCustomersRole = getBaseRoleAssignment(gra)

        // Set the role id
        const allSitesForCustomersRoleIds = uniq(
          allProjectsSitesForCustomers
            .filter((x) => x.roleId !== -1)
            .map((x) => x.roleId)
            .sort()
        )
        // eslint-disable-next-line max-depth
        if (allSitesForCustomersRoleIds.length > 0) {
          allProjectsSitesForCustomersRole.id = allSitesForCustomersRoleIds[0]
        } else {
          allProjectsSitesForCustomersRole.id = -1
        }

        // Set the customerIds
        allProjectsSitesForCustomersRole.dataScope.customerIds = allCustomersIds

        roleAssignments.push(allProjectsSitesForCustomersRole)
      }

      // One role for each sites restriction by customer
      const specificScopesForCustomers = gra.variantDataScopes.customersScopes.filter(
        (x) =>
          (x.siteIds.length || x.projectIds.length || x.contractIds.length) &&
          // Ignore specific roles if global one exists
          !allCustomersIds.includes(x.customerId)
      )

      specificScopesForCustomers.forEach((specificScopeForCustomers) => {
        const specificScopeForCustomerRole = getBaseRoleAssignment(gra)

        // Set the roleId
        const currentAssignedRoleIds = new Set(
          roleAssignments.map((x) => x.id).filter((x) => x !== -1)
        )

        if (!currentAssignedRoleIds.has(specificScopeForCustomers.roleId)) {
          specificScopeForCustomerRole.id = specificScopeForCustomers.roleId
        } else {
          specificScopeForCustomerRole.id = -1
        }

        // Set the customerId
        specificScopeForCustomerRole.dataScope.customerIds = [specificScopeForCustomers.customerId]

        // Set the siteIds
        const uniqSiteIds = uniq(specificScopeForCustomers.siteIds.sort())
        if (uniqSiteIds.length) {
          specificScopeForCustomerRole.dataScope.siteIds = uniqSiteIds
        }

        // Set the projectIds
        const uniqProjectIds = uniq(specificScopeForCustomers.projectIds.sort())
        if (uniqProjectIds.length) {
          specificScopeForCustomerRole.dataScope.projectIds = uniqProjectIds
        }

        // Set the contractIds
        const uniqContractIds = uniq(specificScopeForCustomers.contractIds.sort())
        if (uniqContractIds.length) {
          specificScopeForCustomerRole.dataScope.contractIds = uniqContractIds
        }

        roleAssignments.push(specificScopeForCustomerRole)
      })
    } else if (gra.variantDataScopes.haulierIds && gra.variantDataScopes.haulierIds.length > 0) {
      // Roles with haulierIds
      const haulierIdsRole = getBaseRoleAssignment(gra)

      haulierIdsRole.dataScope.haulierIds = gra.variantDataScopes.haulierIds

      roleAssignments.push(haulierIdsRole)
    } else if (gra.variantDataScopes.payerIds && gra.variantDataScopes.payerIds.length > 0) {
      // Roles with payerIds
      const payerIdsRole = getBaseRoleAssignment(gra)

      payerIdsRole.dataScope.payerIds = gra.variantDataScopes.payerIds

      roleAssignments.push(payerIdsRole)
    } else if (gra.variantDataScopes.plantIds && gra.variantDataScopes.plantIds.length > 0) {
      // Roles with plantIds
      const plantIdsRole = getBaseRoleAssignment(gra)

      plantIdsRole.dataScope.plantIds = gra.variantDataScopes.plantIds
      if (
        gra.variantDataScopes.commentCategories &&
        gra.variantDataScopes.commentCategories.length > 0
      ) {
        // Roles with commentCategories
        plantIdsRole.dataScope.commentCategories = gra.variantDataScopes.commentCategories
      }

      roleAssignments.push(plantIdsRole)
    } else {
      // No variant datascopes && No specific customers
      const simpleRole = getBaseRoleAssignment(gra)
      if (gra.sourceRolesIds && gra.sourceRolesIds.length > 0) {
        simpleRole.id = gra.sourceRolesIds[0]
      }

      if (
        gra.variantDataScopes.commentCategories &&
        gra.variantDataScopes.commentCategories.length > 0
      ) {
        // Roles with commentCategories
        simpleRole.dataScope.commentCategories = gra.variantDataScopes.commentCategories
      }

      roleAssignments.push(simpleRole)
    }
  } else {
    // Access for all customers
    const allCustomersRole = getBaseRoleAssignment(gra)

    if (gra.variantDataScopes.allCustomersRoleId === -1) {
      // Choose the first role if we have one to minimize the operations
      if (gra.sourceRolesIds && gra.sourceRolesIds.length > 0) {
        allCustomersRole.id = gra.sourceRolesIds[0]
      }
    } else {
      // A role already exists for it
      allCustomersRole.id = gra.variantDataScopes.allCustomersRoleId
      if (
        gra.roleType === 'DISPATCHER' &&
        gra.variantDataScopes.plantIds &&
        gra.variantDataScopes.plantIds.length > 0
      ) {
        // Roles with plantIds
        allCustomersRole.dataScope.plantIds = gra.variantDataScopes.plantIds
      }
    }

    if (
      gra.variantDataScopes.commentCategories &&
      gra.variantDataScopes.commentCategories.length > 0
    ) {
      // Roles with commentCategories
      allCustomersRole.dataScope.commentCategories = gra.variantDataScopes.commentCategories
    }

    roleAssignments.push(allCustomersRole)
  }
  // Assign remaining role ids to minimize the operations
  return reassignUnusedRoleIds(roleAssignments, gra)
}

export const cleanGroupRoleAssignments = (
  groupedRoleAssignment: GroupedRoleAssignment
): GroupedRoleAssignment => {
  if (groupedRoleAssignment.variantDataScopes.allCustomersRoleId !== null) {
    groupedRoleAssignment.variantDataScopes.customersScopes = []
    return groupedRoleAssignment
  }
  const customersScopes: Array<{
    customerId: string
    siteIds: string[]
    projectIds: string[]
    contractIds: string[]
    roleId: number
  }> = []

  groupedRoleAssignment.variantDataScopes.customersScopes.forEach((cs) => {
    const csIndex = customersScopes.findIndex(
      (x) => x.customerId.toLowerCase() === cs.customerId.toLowerCase()
    )
    if (csIndex < 0) {
      customersScopes.push(cs)
    } else {
      const currentCs = customersScopes[csIndex]
      if (
        currentCs.siteIds.length > 0 ||
        currentCs.projectIds.length > 0 ||
        currentCs.contractIds.length > 0
      ) {
        if (cs.siteIds.length === 0 && cs.projectIds.length === 0 && cs.contractIds.length === 0) {
          customersScopes[csIndex] = cs
        } else {
          // Merge siteIds
          const mergedSiteIds = uniq(
            cloneDeep(currentCs.siteIds)
              .concat(cloneDeep(cs.siteIds))
              .map((siteId) => siteId.toLowerCase())
              .sort()
          )
          // Merge projectIds
          const mergedProjectIds = uniq(
            cloneDeep(currentCs.projectIds)
              .concat(cloneDeep(cs.projectIds))
              .map((siteId) => siteId.toLowerCase())
              .sort()
          )
          // Merge contractIds
          const mergedContractIds = uniq(
            cloneDeep(currentCs.contractIds)
              .concat(cloneDeep(cs.contractIds))
              .map((siteId) => siteId.toLowerCase())
              .sort()
          )

          customersScopes[csIndex].siteIds = mergedSiteIds
          customersScopes[csIndex].projectIds = mergedProjectIds
          customersScopes[csIndex].contractIds = mergedContractIds
        }
      }
    }
  })

  groupedRoleAssignment.variantDataScopes.customersScopes = customersScopes

  return groupedRoleAssignment
}

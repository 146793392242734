import {ResponsiveTable, useBreakPoints} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RoleAssignment} from '../../../modules/ManageUsers.selectors'

import {useStyles} from './CloneUser.styles'
import {CloneUserListRows} from './CloneUserListRows'

type CloneUserProps = {
  roles: RoleAssignment[]
  cloneableRoleIds: number[]
  setSelectedRolesId: (rolesId: number[]) => void
}

export const CloneUser = ({roles, cloneableRoleIds, setSelectedRolesId}: CloneUserProps) => {
  const {t} = useTranslation()
  const screenSizes = useBreakPoints()
  const isMobile = ['xs', 'sm'].includes(screenSizes)
  const {classes} = useStyles()

  return (
    <Box style={{display: 'flex', flexDirection: 'column', gap: '30px'}}>
      <ResponsiveTable
        columns={CloneUserListRows({cloneableRoleIds})}
        rows={roles.sort((a, b) => cloneableRoleIds.indexOf(b.id) - cloneableRoleIds.indexOf(a.id))}
        onSort={() => {}}
        keyField="id"
        selectionEnabled={true}
        isRowSelectable={(row) => cloneableRoleIds.includes(row.id)}
        getSelectedRows={(rows) => {
          setSelectedRolesId(rows.map((row) => row.id))
        }}
        preselectedRows={{
          rows: roles.filter((role) => cloneableRoleIds.includes(role.id)),
          initial: true
        }}
        isMobile={isMobile}
        promoBanner={
          !roles.every((role) => cloneableRoleIds.includes(role.id)) ? (
            <Box className={classes.banner}>
              {t('manageUser.cloneDialog.insufficientPermissions')}
            </Box>
          ) : null
        }
        promoBannerIndex={roles.findIndex((role) => !cloneableRoleIds.includes(role.id)) - 1}
      />
    </Box>
  )
}
